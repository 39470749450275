import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Membership } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  heroContent: {
    maxWidth: 460,
    margin: '0 auto',
    marginBottom: `${theme.spacing.unit * 5}px`,
    paddingBottom: `${theme.spacing.unit * 3}px`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  tableContainer: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  th: {
    fontSize: '0.9rem',
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
});

/* */
class ApproveSchedule extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { data: { due }, match: { params } } = props;

    this.months = params.months;
    this.state = { schedule: null };

    this.redirect = false;
    if (due.memberships.length === 0) {
      this.redirect = true;
      return;
    }

    const startAt = moment(due.scheduleStartDate);
    const groupSchedule = [];
    due.memberships.forEach((m, i) => {
      const addedAmountOnFirstInstallment = m.members
        .reduce((totalAmount, member) => totalAmount + member.lines
          .reduce((amount, line) => {
            if (line.isPayableInInstallments) {
              const lineOption = (line.options || [])
                .find(o => Number(o.option) === Number(this.months));
              if (lineOption) {
                return amount + lineOption.amount;
              }
              return amount;
            }
            return amount;
          }, 0), 0);

      const scheduledAmount = m.amount - addedAmountOnFirstInstallment;

      const schedule = [];
      const perMonth = Math.floor((scheduledAmount / 100) / this.months) * 100;
      const last = scheduledAmount - ((this.months - 1) * perMonth);

      for (let j = 0; j < this.months; j += 1) {
        const amount = (j === (this.months - 1)) ? last : perMonth;
        const date = moment.max(moment().add(1, 'days'), startAt);
        schedule[j] = {
          amount,
          date: date.format('YYYY-MM-DD'),
        };
        if (!groupSchedule[j]) {
          groupSchedule[j] = {
            amount: 0,
            date: date.format('YYYY-MM-DD'),
          };
        }
        startAt.add(1, 'M');
        groupSchedule[j].amount += amount;
      }

      schedule[0].amount += addedAmountOnFirstInstallment;
      groupSchedule[0].amount += addedAmountOnFirstInstallment;

      due.memberships[i] = {
        ...m,
        transaction: {
          ...m.transaction,
          schedule,
        },
      };
    });

    this.state = { schedule: groupSchedule };
  }

  /* */
  renderSchedule() {
    const { classes } = this.props;
    const { schedule } = this.state;

    return (
      <React.Fragment>
        <Paper className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.th}>
                  {'Date'}
                </TableCell>
                <TableCell className={classes.th} align="right">
                  {'Montant'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.map((l, i) =>
                <TableRow className={classes.row} key={i}>
                  <TableCell>
                    {i18n.l('date.formats.default', new Date(l.date))}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.colorPrimary}>
                    {i18n.l('currency', l.amount / 100)}
                  </TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }

  /* */
  goToPayment() {
    this.props.history.push('/membership/payment');
  }

  /* */
  render() {
    const { schedule } = this.state;
    const { classes, data: { due }, wording } = this.props;

    if (this.redirect) {
      return <Redirect to="/membership" />;
    }

    const title = i18n.t(`home.titles.my_membership_${wording.membership}`);

    return (
      <Screen title={title}>
        <div className={classes.heroContent}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            color="textPrimary">
            {'Votre plan de prélèvement'}
          </Typography>
          <Typography
            align="center"
            color="textSecondary">
            {`A partir du ${i18n.l('date.formats.default', moment(schedule[0].date).toDate())} `}
            {`jusqu'au ${i18n.l('date.formats.default', moment(schedule[schedule.length - 1].date).toDate())}`}
          </Typography>
        </div>
        <Typography
          align="center"
          gutterBottom
          variant="body1">
          {i18n.t(`membership.messages.approve_schedule_${wording.membership}`, {
            amount: i18n.l('currency', due.total / 100),
            months: this.months,
          })}
        </Typography>
        {this.renderSchedule()}
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => this.goToPayment()}>
          {"Valider l'échéancier"}
        </Button>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, membershipData }) => ({
  data: membershipData,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(ApproveSchedule);

export default connect(
  mapStateToProps,
  Membership.actions,
)(StyledComponent);
