import React from 'react';
import { Button, CircularProgress, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  inputContainer: {
    width: 56,
  },
  input: {
    fontSize: 70,
    fontWeight: 300,
    paddingBottom: 8,
    textAlign: 'center',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  submitButton: {
    display: 'inherit',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
class ActivationForm extends React.Component {
  /* */
  componentDidMount() {
    this.code1.focus();
  }

  /* */
  createInput(name, next) {
    const { classes } = this.props;

    return (
      <Input
        required
        classes={{
          root: classes.inputContainer,
          input: classes.input,
        }}
        inputProps={{
          maxLength: 1,
        }}
        name={name}
        value={this.props.values[name]}
        onBlur={this.props.handleBlur}
        onChange={(e) => {
          this.props.handleChange(e);
          if (next && e.target.value.length === 1) next();
        }}
        error={!!(this.props.errors[name])}
        inputRef={(c) => { this[name] = c; }} />
    );
  }

  /* */
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.inputRow}>
          {this.createInput('code1', () => this.code2.focus())}
          {this.createInput('code2', () => this.code3.focus())}
          {this.createInput('code3', () => this.code4.focus())}
          {this.createInput('code4', () => this.code5.focus())}
          {this.createInput('code5', () => this.code6.focus())}
          {this.createInput('code6', null)}
        </div>
        <Button
          fullWidth
          className={classes.submitButton}
          disabled={this.props.isSubmitting}
          onClick={this.props.handleSubmit}
          size="large"
          color="primary"
          variant="contained">
          {this.props.isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : ('Valider')}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ActivationForm);
