import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as qs from 'query-string';

import { Screen } from '_components/core';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { FolderOpen, FolderShared } from '@material-ui/icons';
import MMyFolder from './MyFolder';
import MSharedWithMe from './SharedWithMe';

/* */
const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 2,
  },
  center: {
    padding: theme.spacing.unit,
  },
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});
/* */
const Documents = ({ location, history }) => {
  const { from: fromWhere } = qs.parse(location.search);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Screen
      onBackPress={fromWhere ? () => history.goBack() : null}
      title="Documents"
    >
      <AppBar
        position="fixed"
        color="default"
        style={{
          zIndex: 1,
          top: 65,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Mes documents" icon={<FolderOpen />} />
          <Tab label="Partagés avec moi" icon={<FolderShared />} />
        </Tabs>
      </AppBar>
      {value === 0 && <MMyFolder />}
      {value === 1 && <MSharedWithMe />}
    </Screen>
  );
};

const mapStateToProps = ({ app: { account, golf } }) => ({ account, golf });

const StyledComponent = withStyles(styles)(Documents);

export default connect(mapStateToProps)(StyledComponent);
