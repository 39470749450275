import React, { useState } from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { withStyles } from '@material-ui/core/styles';
import { Document } from '@aps-management/primapp-common';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Fab,
  Paper,
  Dialog,
  Button,
  TextField,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import { FileIcon } from '_components/elements';
import apolloClient from '_utils/masterClient';

import Upload from './Upload';

/* */
const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  center: { padding: theme.spacing.unit },
  iconColumn: { width: 75 },
  dateColumn: { width: 200 },
  actionColumn: { width: 200 },
});

/* */
const DocumentList = ({ account, golf, classes }) => {
  /* */
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(null);
  const [filename, setFilename] = useState(null);

  const [updating, setUpdating] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [downloading, setDownloading] = useState(null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState([]);

  /* */
  React.useEffect(() => {
    setLoading(true);

    Document.api
      .getSharedDocuments(apolloClient, {
        ownerId: account.id,
        readerId: golf.id,
      })
      .then(docs => setDocuments(docs))
      .catch(setError)
      .finally(() => setLoading(false));
  }, [account, golf]);

  /* */
  const handleClose = () => {
    setOpen(false);
  };
  const closeConfirm = () => {
    setConfirm(null);
  };
  const closeFilename = () => {
    setFilename(null);
  };

  /* */
  const handleUpload = (files) => {
    if (files?.length) {
      return Promise.all(
        files.map(file =>
          Document.api.addDocument(apolloClient, {
            file,
            readerId: golf.id,
          })),
      )
        .then(docs => setDocuments([...(docs || []), ...(documents || [])]))
        .then(() => setSuccess('Fichier enregistré.'))
        .catch(setError)
        .finally(() => setOpen(false));
    }

    return null;
  };

  /* */
  const handleUpdate = () => {
    if (filename && filename.name && filename.name.length) {
      setUpdating(filename.id);

      return Document.api
        .updateDocument(apolloClient, {
          id: filename.id,
          filename: `${filename.name}.${filename.ext}`,
        })
        .then(doc =>
          setDocuments(documents.map(d => (d.id === doc.id ? doc : d))))
        .then(() => setSuccess('Fichier modifié.'))
        .catch(setError)
        .finally(() => {
          setFilename(null);
          setUpdating(null);
        });
    }

    return null;
  };

  /* */
  const handleDelete = () => {
    if (!confirm) return null;

    setDeleting(confirm.id);

    return Document.api
      .deleteDocument(apolloClient, {
        id: confirm.id,
      })
      .then(() => setDocuments(documents.filter(d => d.id !== confirm.id)))
      .then(() => setSuccess('Fichier supprimé.'))
      .catch(setError)
      .finally(() => {
        setConfirm(null);
        setDeleting(null);
      });
  };

  /* */
  const downloadFile = (d) => {
    setDownloading(d.id);
    master
      .blob(`document/${d.id}`)
      .then(data => fileDownload(data, d.filename))
      .catch(setError)
      .finally(() => setDownloading(null));
  };

  /* */
  const renderDocuments = () => {
    if (!documents?.length) {
      return (
        <Typography variant="body2" align="center" className={classes.center}>
          {'Aucun fichier trouvé.'}
        </Typography>
      );
    }

    return (
      <Table padding="dense">
        <TableHead>
          <TableRow>
            <TableCell className={classes.iconColumn}>{''}</TableCell>
            <TableCell>{'Nom'}</TableCell>
            <TableCell className={classes.dateColumn}>{'Date'}</TableCell>
            <TableCell className={classes.actionColumn}>{''}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody key="tbody">
          {(documents || []).map((d, key) => (
            <TableRow key={key}>
              <TableCell>
                <FileIcon mimetype={d.mimetype} />
              </TableCell>
              <TableCell>{d.name}</TableCell>
              <TableCell>
                {i18n.l('date.formats.short_y', new Date(d.createdAt))}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="Télécharger"
                  onClick={() => downloadFile(d)}
                >
                  {downloading === d.id ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>
                {d.ownerId === account.id && (
                  <IconButton
                    aria-label="Changer le nom"
                    onClick={() => setFilename(d)}
                  >
                    {updating === d.id ? (
                      <CircularProgress color="secondary" size={24} />
                    ) : (
                      <EditIcon />
                    )}
                  </IconButton>
                )}
                {d.ownerId === account.id && (
                  <IconButton
                    aria-label="Supprimer"
                    onClick={() => setConfirm(d)}
                  >
                    {deleting === d.id ? (
                      <CircularProgress color="secondary" size={24} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  /* */
  const renderConfirmDialog = () => {
    if (!confirm) return null;

    return (
      <Dialog fullWidth open={Boolean(confirm)} onClose={closeConfirm}>
        <DialogTitle>
          {'Confirmer la suppression'}
          <IconButton
            color="inherit"
            aria-label="Fermer"
            onClick={closeConfirm}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {`Supprimer le document "${confirm.filename}" ?`}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeConfirm}>
            {'Annuler'}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDelete}
            disabled={Boolean(deleting)}
          >
            {deleting ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              'Supprimer'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  /* */
  const renderUpdateDialog = () => {
    if (!filename) return null;

    return (
      <Dialog fullWidth open={Boolean(filename)} onClose={closeFilename}>
        <DialogTitle>
          {'Renommer le fichier'}
          <IconButton
            color="inherit"
            aria-label="Fermer"
            onClick={closeFilename}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            fullWidth
            name="name"
            value={filename?.name || ''}
            onChange={({ target }) =>
              setFilename({
                ...filename,
                name: target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">.{filename?.ext}</InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeFilename}>
            {'Annuler'}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleUpdate}
            disabled={Boolean(updating)}
          >
            {updating ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              'Enregistrer'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Screen error={error} loading={loading} success={success}>
      <Box
        pt={4}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">{'Mon dossier'}</Typography>
        <Fab
          color="secondary"
          aria-label="Ajouter un document"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Paper>{renderDocuments()}</Paper>
      <Upload
        open={open}
        handleError={setError}
        handleClose={handleClose}
        handleUpload={handleUpload}
      />
      {renderConfirmDialog()}
      {renderUpdateDialog()}
    </Screen>
  );
};

const mapStateToProps = ({ app: { account, golf } }) => ({ account, golf });

const StyledComponent = withStyles(styles)(DocumentList);

export default connect(mapStateToProps)(StyledComponent);
