import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { App, Directory } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Fab,
  Tab,
  List,
  Tabs,
  Paper,
  AppBar,
  Button,
  Dialog,
  Toolbar,
  Checkbox,
  ListItem,
  TextField,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import {
  Add as AddIcon,
  List as ListIcon,
  Star as StarIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Contacts as ContactsIcon,
} from '@material-ui/icons';
/* */
import i18n from '_utils/i18n';
import { Wrapper } from '_components/elements';
import apolloClient from '_utils/apolloClient';
import AddContact from '_components/AddContact';

/* */
const styles = theme => ({
  tabs: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  rightButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  flex: { flex: 1 },
  starIcon: { color: '#FDD835' },
  appBar: { position: 'relative' },
  chip: { margin: theme.spacing.unit },
  searchInput: { marginBottom: theme.spacing.unit * 3 },
});

/* */
const Loading = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress
      size={32}
      thickness={4}
      color="secondary" />
  </Box>
);

/* */
const TAB_MEMBERS = 0;
const TAB_CONTACTS = 1;

/* */
class ChoosePartners extends React.Component {
  /* */
  static defaultProps = {
    open: false,
    onClick: null,
    disableOwner: true,
    title: 'Vos partenaires',
    tabs: ['members', 'contacts'],
  };

  /* */
  constructor(props) {
    super(props);

    const { tabs, app } = props;
    const { account, golf } = app;

    this.accountMatch = App.functions.match(account, golf);

    const activeTab = tabs.includes('members')
      ? TAB_MEMBERS
      : TAB_CONTACTS;

    this.state = {
      // Data
      query: '',
      members: [],
      // UI
      activeTab,
      error: null,
      openDialog: false,
      stepsEnabled: false,
      noResultFound: false,
      // Loading
      memberLoading: true,
      contactLoading: true,
    };

    this.partners = [];
    this.firstLoad = true;
  }

  /* */
  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open === true) {
      this.load();
    }
  }

  /* */
  load = () => {
    if (!this.firstLoad) return;
    this.firstLoad = false;

    const { app: { golf } } = this.props;

    if (this.props.tabs.includes('members')) {
      Directory.api.searchPartners(apolloClient, { golfId: golf.id }, 'no-cache')
        .then(({ partners }) => {
          this.partners = partners;
          this.setState({ members: partners });
        })
        .catch(e => this.setState({ error: e.message }))
        .finally(() => this.setState({ memberLoading: false }));
    }

    if (this.props.contacts === null
        && this.props.tabs.includes('contacts')) {
      Directory.api.getContacts(apolloClient, {}, 'no-cache')
        .then(({ contacts }) => {
          this.props.setContactsList(contacts);
        })
        .catch(e => this.setState({ error: e.message }))
        .finally(() => this.setState({ contactLoading: false }));
    } else {
      this.setState({ contactLoading: false });
    }
  }

  /* */
  handleSearch = (e) => {
    this.setState({ query: e.target.value });
    this.searchMembers();
  }

  /* */
  searchMembers = _.debounce(() => {
    const { partners } = this;
    const { query } = this.state;
    const { golf } = this.props.app;

    if (query.trim().length >= 3) {
      this.setState({
        error: null,
        memberLoading: true,
      });
      Directory.api.searchMembers(apolloClient, {
        limit: 12,
        name: query,
        fullData: 1,
        golfId: golf.id,
      }, 'no-cache')
        .then(({ members }) => this.setState({
          members,
          noResultFound: (members.length === 0),
        }))
        .catch(e => this.setState({ error: e.message }))
        .finally(() => this.setState({ memberLoading: false }));
    } else if (query.length === 0 && partners.length > 0) {
      this.setState({ members: partners });
    }
  }, 1000);

  /* */
  handleClose = () => this.props.onClose();

  /* */
  handleTabChange = (event, activeTab) => this.setState({ activeTab });

  /* */
  handleAddContact = () => {
    this.setState(state => ({ openDialog: !state.openDialog }));
  };

  /* */
  handleSelect = async (i, type) => {
    const { members } = this.state;
    const { contacts } = this.props;

    const player = (type === 'member')
      ? { ...members[i], isMember: true }
      : { ...contacts[i], isMember: false, reference: 'UNKNOWN' };

    await this.props.onClick('select', { ...player, type });
  }

  /* */
  handleUnselect = async (i, type) => {
    const {
      contacts,
      players,
    } = this.props;
    const { members } = this.state;

    const index = (type === 'member')
      ? players.findIndex(p => p.reference === members[i].reference)
      : players.findIndex(p => p.uid === contacts[i].uid);

    this.props.onClick('unselect', index);
  }

  /* */
  renderMemberList() {
    const { members, memberLoading, noResultFound } = this.state;

    if (memberLoading) return <Loading />;

    if (noResultFound) {
      return (
        <Typography weight="light">{'Aucun résultat.'}</Typography>
      );
    }

    if (members.length > 0) {
      return (
        <Paper>
          <List disablePadding>{members.map(this.renderMember)}</List>
        </Paper>
      );
    }

    return null;
  }

  /* */
  renderMember = (m, i) => {
    const { partners: favorites, accountMatch } = this;
    const { classes, players, disableOwner } = this.props;

    const refs = players.filter(p => p.reference !== 'UNKNOWN').map(p => p.reference);
    const checked = refs.includes(m.reference);

    const handleClick = () => (
      !checked
        ? this.handleSelect(i, 'member')
        : this.handleUnselect(i, 'member')
    );

    const isOwner = m.reference === accountMatch.reference;
    const disabled = isOwner && disableOwner;

    const isFavorite = favorites.find(p => m.reference === p.reference);

    return (
      <ListItem
        button
        key={i}
        disableRipple
        disabled={disabled}
        onClick={handleClick}
        classes={{ root: classes.listItem }}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked}
          disabled={disabled} />
        <ListItemText primary={`${m.firstname} ${m.lastname}`} />
        {isFavorite && (
          <ListItemIcon className={classes.starIcon}>
            <StarIcon />
          </ListItemIcon>
        )}
      </ListItem>
    );
  }

  /* */
  renderContactList() {
    const { contacts } = this.props;
    const { contactLoading } = this.state;

    if (contactLoading) return <Loading />;

    if (contacts && contacts.length > 0) {
      return (
        <Paper>
          <List disablePadding>{contacts.map(this.renderContact)}</List>
        </Paper>
      );
    }

    return (
      <Typography weight="light">
        {'Aucun contact dans votre répertoire.'}
      </Typography>
    );
  }

  /* */
  renderContact = (c, i) => {
    const { classes, players } = this.props;

    const uids = players.filter(p => !!p.uid).map(p => p.uid);
    const checked = uids.includes(c.uid);

    const handleClick = () => (
      !checked
        ? this.handleSelect(i, 'contact')
        : this.handleUnselect(i, 'contact')
    );

    return (
      <ListItem
        button
        disableRipple
        key={i}
        onClick={handleClick}
        classes={{ root: classes.listItem }}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked} />
        <ListItemText primary={`${c.firstname} ${c.lastname}`} />
      </ListItem>
    );
  };

  /* */
  renderContactTab() {
    const { accountMatch: { isMember } } = this;
    const title = isMember ? 'Choisir un joueur extérieur' : 'Choisir un joueur';

    return (
      <Fragment>
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Typography align="center" variant="h5">
            {title}
          </Typography>
          <Fab
            color="secondary"
            id="cp-contact-add"
            aria-label="Ajouter un contact"
            onClick={this.handleAddContact}>
            <AddIcon />
          </Fab>
        </Box>
        {this.renderContactList()}
      </Fragment>
    );
  }

  /* */
  renderMemberTab() {
    const { query } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Typography align="center" variant="h5" paragraph>
          {'Rechercher un membre'}
        </Typography>
        <TextField
          fullWidth
          value={query}
          variant="outlined"
          placeholder="Nom ou prénom"
          onChange={this.handleSearch}
          className={classes.searchInput}
          InputProps={{
            id: 'cp-member-search',
            endAdornment: <SearchIcon />,
          }} />
        {this.renderMemberList()}
      </div>
    );
  }

  /* */
  renderTabs() {
    const { activeTab } = this.state;
    const {
      classes,
      wording,
      tabs,
    } = this.props;

    if (tabs.length > 1) {
      return (
        <Tabs
          value={activeTab}
          variant="fullWidth"
          className={classes.tabs}
          onChange={this.handleTabChange}>
          <Tab
            id='cp-member-title'
            icon={<ListIcon />}
            label={i18n.t(`booking.titles.list_members_${wording.member}`)} />
          <Tab
            id='cp-contact-title'
            icon={<ContactsIcon />}
            label={i18n.t('booking.titles.my_contacts')} />
        </Tabs>
      );
    }

    return null;
  }

  /* */
  render() {
    const {
      activeTab,
      openDialog,
    } = this.state;
    const {
      open,
      title,
      classes,
    } = this.props;

    return (
      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          classes={{ paperFullScreen: classes.dialog }}>
          <AppBar color="primary" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                className={classes.rightButton}
                onClick={this.handleClose}
                aria-label="Fermer">
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.flex}>
                {title}
              </Typography>
              <Button color="inherit" onClick={this.handleClose} id="cp-save">
                {'Enregistrer'}
              </Button>
            </Toolbar>
          </AppBar>
          {this.renderTabs()}
          <Wrapper layout="fixed">
            {activeTab === TAB_MEMBERS && this.renderMemberTab()}
            {activeTab === TAB_CONTACTS && this.renderContactTab()}
          </Wrapper>
        </Dialog>
        <AddContact open={openDialog} onClose={this.handleAddContact} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  app,
  directory: { contacts },
}) => ({
  app,
  contacts,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(ChoosePartners);

export default connect(mapStateToProps, Directory.actions)(StyledComponent);
