import React from 'react';
import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    display: 'inherit',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
class RegisterFinalForm extends React.Component {
  /* */
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <TextField
          className={classes.textField}
          onBlur={this.props.handleBlur}
          onChange={this.props.handleChange}
          value={this.props.values.firstname}
          label="Prénom"
          name='firstname'
          error={!!(this.props.errors.firstname)}
          fullWidth
          helperText={this.props.errors.firstname}
          required
        />
        <TextField
          className={classes.textField}
          onBlur={this.props.handleBlur}
          onChange={this.props.handleChange}
          value={this.props.values.lastname}
          label="Nom"
          name='lastname'
          error={!!(this.props.errors.lastname)}
          fullWidth
          helperText={this.props.errors.lastname}
          required
        />
        <TextField
          className={classes.textField}
          onBlur={this.props.handleBlur}
          onChange={this.props.handleChange}
          value={this.props.values.phone}
          label="Téléphone"
          name='phone'
          error={!!(this.props.errors.phone)}
          fullWidth
          helperText={this.props.errors.phone}
          required
        />
        <Button
          fullWidth
          className={classes.submitButton}
          disabled={this.props.isSubmitting}
          onClick={this.props.handleSubmit}
          size="large"
          color="primary"
          variant="contained">
          {this.props.isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : ('Finaliser')}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(RegisterFinalForm);
