import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft as BackIcon } from '@material-ui/icons';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';

/* */
const styles = {
  title: {
    flex: 1,
  },
  leftButton: {
    marginLeft: -12,
    marginRight: 0,
  },
};

const CustomAppbar = (props) => {
  const {
    classes,
    onBackPress,
    title,
  } = props;

  return (
    <AppBar position="static">
      <Toolbar>
        {onBackPress && (
          <IconButton
            className={classes.leftButton}
            color="inherit"
            onClick={onBackPress}>
            <BackIcon />
          </IconButton>
        )}
        <Typography
          align="center"
          className={classes.title}
          color="inherit"
          variant="h5">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const StyledComponent = withStyles(styles)(CustomAppbar);

export default StyledComponent;
