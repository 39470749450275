import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { helpers, Membership } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  AccountCircle as AccountIcon,
  Close as CloseIcon,
  Done as DoneIcon,
} from '@material-ui/icons';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  // Slider,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  tabs: {
    backgroundColor: 'transparent',
    marginBottom: theme.spacing.unit * 4,
  },
  heroContent: {
    maxWidth: 460,
    margin: '0 auto',
    marginBottom: `${theme.spacing.unit * 5}px`,
    paddingBottom: `${theme.spacing.unit * 3}px`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  listTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  listContainer: {
    marginBottom: `${theme.spacing.unit * 5}px`,
  },
  totalLine: {
    fontSize: 16,
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  remaining: {
    color: theme.palette.error.main,
  },
  payButton: {
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  th: {
    fontSize: '0.9rem',
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  bold: { fontWeight: 'bold' },
  options: {
    display: 'flex',
    alignItems: 'center',
  },
  optionGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
});

/* */
function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

/* */
// function valuetext(value) {
//   return `${value} mois`;
// }


/* */
class MembershipView extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      // Screen
      error: null,
      loading: false,
      // Data
      months: 1,
      currentTab: 0,
    };

    const { data: { membership } } = props;

    this.redirect = false;
    if (membership === null) {
      this.redirect = true;
      return;
    }

    const options = membership.payment.caseOptions
      ? membership.payment.caseOptions.map(o => o.option)
      : membership.payment.options;

    this.options = (options || []).reduce((opts, option) => ({
      ...opts,
      [option]: 0,
    }), {});
    membership.total.options.forEach(({ option, amount }) => {
      this.options[option] = (this.options[option] || 0) + amount;
    });
  }

  /* */
  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };

  /* */
  goToCashPayment() {
    const { data: { membership } } = this.props;

    this.props.updateDue({
      memberships: [{
        ...membership,
        amount: membership.total.due,
        transaction: {
          cash: true,
          schedule: null,
        },
      }],
      cash: true,
      scheduleStartDate: null,
      total: membership.total.due,
    });
    this.props.history.push('/membership/payment');
  }

  /* */
  goToApproveSchedule(months) {
    const { data: { membership } } = this.props;

    // compute membership total due based on option
    const option = (membership.total.options || []).find(o => o.option === Number(months));
    const caseOption = (membership.payment.caseOptions || [])
      .find(o => (o.option === Number(months)));

    this.props.updateDue({
      memberships: [{
        ...membership,
        amount: option ? option.amount : membership.total.due,
        transaction: {
          cash: false,
          schedule: [],
        },
      }],
      cash: false,
      scheduleStartDate: caseOption
        ? caseOption.scheduleStartDate
        : membership.payment.scheduleStartDate,
      total: option ? option.amount : membership.total.due,
    });
    this.props.history.push(`/membership/schedule/${months}`);
  }

  /* */
  renderTabs() {
    const { currentTab } = this.state;
    const { classes, data: { membership } } = this.props;

    if (membership.paymentSchedule.length === 0) return null;

    return (
      <Tabs
        value={currentTab}
        variant="fullWidth"
        className={classes.tabs}
        onChange={this.handleTabChange}>
        <LinkTab label="Informations" href="#" />
        <LinkTab label="Échéancier" href="#" />
      </Tabs>
    );
  }

  /* */
  renderScheduleTab() {
    const { classes, data: { membership }, wording } = this.props;
    const frequency = membership.paymentSchedule.length;

    return (
      <React.Fragment>
        <div className={classes.heroContent}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            color="textPrimary">
            {helpers.string.ucfirst(i18n.t(`terms.membership_${wording.membership}`))} {membership.year}
          </Typography>
          <Typography
            gutterBottom
            align="center"
            color="textSecondary">
             {'Votre plan de paiement'}
          </Typography>
          <Typography
            align="center"
            color="textSecondary">
            {`A partir du ${i18n.l('date.formats.default', moment(membership.paymentSchedule[0].date).toDate())} `}
            {`jusqu'au ${i18n.l('date.formats.default', moment(membership.paymentSchedule[frequency - 1].date).toDate())}`}
          </Typography>
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.th}>{'Date'}</TableCell>
                <TableCell className={classes.th} align="right">{'Montant'}</TableCell>
                <TableCell className={classes.th} align="right">{'Réglé'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {membership.paymentSchedule.map((row, i) =>
                <TableRow key={i}>
                  <TableCell>
                    {i18n.l('date.formats.default', moment(row.date).toDate())}
                  </TableCell>
                  <TableCell align="right">
                    {i18n.l('currency', row.amount / 100)}
                  </TableCell>
                  <TableCell align="right">
                    {row.status === 1 && <DoneIcon color="primary" />}
                    {row.status === 2 && <CloseIcon color="error" />}
                  </TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }

  /* */
  renderInfosTab() {
    const { classes, data: { membership }, wording } = this.props;

    // const isPayableInInstallments = membership.members
    // .some(m => m.lines
    // .some(l => l.isPayableInInstallments);

    return (
      <React.Fragment>
        <div className={classes.heroContent}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            color="textPrimary">
            {helpers.string.ucfirst(i18n.t(`terms.membership_${wording.membership}`))} {membership.year}
          </Typography>
          <Typography
            gutterBottom
            align="center"
            color="textSecondary">
            {`Période du ${i18n.l('date.formats.default', moment(membership.period.from).toDate())} `}
            {`au ${i18n.l('date.formats.default', moment(membership.period.to).toDate())}`}
          </Typography>
          <Typography
            align="center"
            color="textSecondary">
            {`Établi le ${i18n.l('date.formats.default', moment(membership.createdAt).toDate())}`}
          </Typography>
        </div>
        <Grid container spacing={24}>
          {membership.members.map((member, i) => this.renderMemberDetails(member, i))}
        </Grid>
        {this.renderTotal()}
        {this.renderOptions()}
        {this.renderPayment()}
      </React.Fragment>
    );
  }

  /* */
  renderMemberDetails(member, index) {
    const { classes, data: { membership } } = this.props;
    const { months } = this.state;

    const showSubtotal = membership.members.length > 1 && member.lines.length > 1;
    const amounts = member.lines.map((line) => {
      const option = (line.options || []).find(o => o.option === Number(months));
      return option ? option.amount : line.amount;
    });
    const subtotal = amounts.reduce((counter, amount) => counter + amount, 0);

    return (
      <Grid item key={index} xs={12} md={6}>
        <div className={classes.listTitle}>
          <AccountIcon className="mr-10" />
          <Typography variant="subtitle1">
            {`${member.firstName} ${member.lastName.toUpperCase()}`}
          </Typography>
        </div>
        <Paper className={classes.listContainer}>
          <List disablePadding>
            {member.lines.map((l, i) => {
              let secondary = null;
              if (l.isPayableInInstallments) {
                secondary = 'Inclus dans la première échéance';
              }

              return (
                <ListItem key={i} divider>
                  <ListItemText
                    primary={l.name}
                    secondary={secondary}
                    primaryTypographyProps={{ variant: 'body2' }}
                    secondaryTypographyProps={{ variant: 'caption' }} />
                  <Typography variant="body2">
                    {i18n.l('currency', (amounts[i] || l.amount) / 100)}
                  </Typography>
                </ListItem>
              );
            })}
            {showSubtotal && (
              <ListItem>
                <ListItemText
                  primary="Sous-total"
                  classes={{ primary: classes.bold }}
                  primaryTypographyProps={{ color: 'primary', variant: 'body1' }} />
                <Typography
                  color="primary"
                  variant="body1"
                  className={classes.bold}>
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderTotal() {
    const { classes, data: { membership } } = this.props;
    const { months } = this.state;
    const option = (membership.total.options || []).find(o => o.option === Number(months));
    const amount = option ? option.amount : membership.total.amount;
    const remainingAmount = amount - membership.total.paid;

    return (
      <Paper className={classes.listContainer}>
        <List disablePadding subheader={
          <ListSubheader
            color="inherit"
            className={classes.totalLine}>
            {'Total'}
          </ListSubheader>
        }>
          <ListItem>
            <ListItemText primary="Montant" />
            <Typography variant="subtitle1">
              {i18n.l('currency', amount / 100)}
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Déjà réglé" />
            <Typography variant="subtitle1">
              {i18n.l('currency', membership.total.paid / 100)}
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Restant dû" />
            <Typography
              variant="subtitle1"
              className={classNames({ [classes.remaining]: remainingAmount > 0 })}>
              {i18n.l('currency', remainingAmount / 100)}
            </Typography>
          </ListItem>
        </List>
      </Paper>
    );
  }

  /* */
  renderPaymentOption(months) {
    const { classes, data: { membership } } = this.props;

    const caseOption = (membership.payment.caseOptions || [])
      .find(o => (o.option === Number(months)));

    const scheduleDeadlineDate = moment.max(moment(), caseOption
      ? moment(caseOption.scheduleDeadlineDate)
      : moment(membership.payment.scheduleDeadlineDate));

    const scheduleStartDate = moment.max(moment().add(1, 'days'), caseOption
      ? moment(caseOption.scheduleStartDate)
      : moment(membership.payment.scheduleStartDate));

    return (
      <React.Fragment>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          className={classes.payButton}
          onClick={() => this.goToApproveSchedule(months)}>
          {`Payer en ${months} mensualités`}
        </Button>
        {(scheduleDeadlineDate || scheduleStartDate) && (
          <Box mb={2}>
            {scheduleDeadlineDate.isValid() && (
              <Typography color="textSecondary" align="center">
                {`Vous avez la possibilité de passer en paiement mensuel jusqu'au ${i18n.l('date.formats.default', scheduleDeadlineDate.toDate())}. `}
              </Typography>
            )}
            {scheduleStartDate.isValid() && (
              <Typography color="textSecondary" align="center">
                {`La première échéance sera au ${i18n.l('date.formats.default', scheduleStartDate.toDate())}.`}
              </Typography>
            )}
          </Box>
        )}
      </React.Fragment>
    );
  }

  /* */
  renderPayment() {
    const { classes, data: { membership } } = this.props;
    if (membership.total.due === 0) return null;

    const { months } = this.state;
    if (!this.options[months]) return null;

    switch (months) {
      case 1:
      case '1':
        return (
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            className={classes.payButton}
            onClick={() => this.goToCashPayment()}>
            {'Payer comptant'}
          </Button>
        );
      default:
        return this.renderPaymentOption(months);
    }
  }

  renderOptions() {
    const { classes, data: { membership } } = this.props;
    if (membership.total.due === 0) return null;

    const options = Object.keys(this.options || {});
    if (options.length <= 1) return null;

    return (
      <Paper className={classes.listContainer}>
        <List disablePadding subheader={
          <ListSubheader
            color="inherit"
            className={classes.totalLine}>
            {'Mon échéancier'}
          </ListSubheader>
        }>
          <FormControl component="fieldset" className={classes.options}>
            <RadioGroup
              name="months"
              value={`${this.state.months}`}
              className={classes.optionGroup}
              onChange={e => this.setState({ months: e.target.value })}>
                {options.map(m => (
                  <FormControlLabel
                    key={m}
                    value={`${m}`}
                    labelPlacement="bottom"
                    label={
                      <>
                        <Typography align="center">
                          {i18n.t('membership.monthly_payment', { count: Number(m) })}
                        </Typography>
                        {((options.includes('1') && m !== '1')
                          && ((this.options[Number(m)] - this.options[1]) > 0))
                          && (
                            <Typography align="center" variant="caption">
                              {`(+ ${i18n.l('currency', (this.options[Number(m)] - this.options[1]) / 100)})`}
                            </Typography>
                          )}
                      </>
                    }
                    control={<Radio color="secondary" />}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </List>
      </Paper>
    );
  }

  /* */
  render() {
    const { wording } = this.props;
    const { currentTab } = this.state;

    if (this.redirect) {
      return <Redirect to="/membership" />;
    }

    const title = i18n.t(`home.titles.my_membership_${wording.membership}`);

    return (
      <Screen title={title}>
        {this.renderTabs()}
        {currentTab === 0 && this.renderInfosTab()}
        {currentTab === 1 && this.renderScheduleTab()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, membershipData }) => ({
  data: membershipData,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(MembershipView);

export default connect(
  mapStateToProps,
  Membership.actions,
)(StyledComponent);
