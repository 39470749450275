import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { App } from '@aps-management/primapp-common';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { RegisterFinalForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  lastname: validators.required,
  firstname: validators.required,
  phone: validators.required,
});

/* */
const styles = theme => ({
  title: {
    marginBottom: theme.spacing.unit * 4,
  },
});

/* */
class RegisterFinal extends React.Component {
  /* */
  state = {
    error: null,
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { user: { email }, activationData } = this.props.app;

    this.setState({ error: null });

    this.props.setAccount(apolloClient, {
      email,
      activationData: activationData && {
        golfId: activationData.golf.id,
        reference: activationData.reference,
      },
      ...values,
    })
      .then(() => window.location.reload())
      .catch((error) => {
        this.setState({ error: error.message });
        setSubmitting(false);
      });
  };

  /* */
  render() {
    const { error } = this.state;
    const { classes, app: { activationData } } = this.props;

    const {
      firstname = '',
      lastname = '',
      phone = '',
    } = activationData || {};

    return (
      <Screen
        error={error}
        title="Activer mon compte">
        <Typography
          align="center"
          className={classes.title}
          color="primary"
          component="h2"
          variant="h4">
          {'A propos de vous'}
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start">
          <Grid item md={8} sm={10} xs={12}>
            <Formik
              initialValues={{
                firstname,
                lastname,
                phone,
              }}
              onSubmit={this.onSubmit}
              component={RegisterFinalForm}
              validationSchema={validationSchema} />
          </Grid>
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ app });

const StyledComponent = withStyles(styles)(RegisterFinal);

export default connect(
  mapStateToProps,
  App.actions,
)(StyledComponent);
