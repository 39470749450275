import store from '_config/store';
import { masterClient } from '@aps-management/primapp-common';

function getContext() {
  const context = {};
  const {
    golf,
    token,
  } = store.getState().app;
  if (golf) context.golfId = golf.id;
  if (token) context.sessionToken = token;
  return context;
}

/* */
const client = {
  /* */
  query: ({
    query,
    variables,
    fetchPolicy,
    errorPolicy,
  }) => (masterClient.query({
    query,
    variables,
    fetchPolicy,
    errorPolicy,
    context: getContext(),
  })),
  /* */
  mutate: ({
    mutation,
    variables,
    errorPolicy,
  }) => (masterClient.mutate({
    mutation,
    variables,
    errorPolicy,
    context: getContext(),
  })),
};

export default client;
