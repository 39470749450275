/* eslint global-require: 0 */
import _ from 'lodash/fp/object';
import config from './env/config.json';

/* */
function envConfig() {
  if (process.env.NODE_ENV === 'development') {
    return require('./env/config_dev.json');
  }
  return {};
}

const conf = _.defaultsDeep(config, envConfig());
global.apiBasePath = conf.apiBasePath;
global.masterBasePath = conf.masterBasePath;
global.booker = 'EMBED';
// global.origin = 'golf-slug';
global.apiVersion = 1.0;
if (process.env.REACT_APP_TOKEN) {
  conf.token = process.env.REACT_APP_TOKEN;
}

export default conf;
