import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import FaceIcon from '@material-ui/icons/Face';
import LockIcon from '@material-ui/icons/Lock';
import RemoveIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  App,
  helpers,
  Booking,
  HELP_URL,
  TEE_TIME_MAX_PLAYERS,
} from '@aps-management/primapp-common';
import {
  Grid,
  List,
  Paper,
  Avatar,
  Button,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import ChoosePartners from '_components/ChoosePartnersEdit'; // Edit mode

// import sampleData from './data.json';

/* */
const styles = theme => ({
  header: {
    overflow: 'hidden',
    position: 'relative',
    boxShadow: theme.shadows[2],
    backgroundColor: 'transparent',
  },
  avatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.text.primary,
  },
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  removeBtn: {
    color: '#ffffff',
    backgroundColor: theme.palette.error.main,
  },
  alert: {
    color: '#ffffff',
    backgroundColor: '#FF3446',
    padding: theme.spacing.unit * 1,
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
const getPlayerName = player => ((player.firstname && player.lastname)
  ? `${player.firstname} ${player.lastname}`
  : helpers.string.ucfirst(i18n.t('terms.anonymous')));

/* */
class BookingEdit extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      // Data
      others: [],
      players: [],
      accessories: [],
      onError: [],
      // UX
      waitingUpdate: false,
      waitingConfirm: false,
      openConfirmDialog: false,
      openPartnersDialog: false,
      // Screen
      error: null,
      loading: true,
      success: null,
    };

    const {
      match,
      details,
      app: { account, golf },
    } = props;

    this.id = match.params.id;

    this.updates = null; // store updates

    this.accountMatch = App.functions.match(account, golf);
    this.allowBookForOthers = golf.options.booking.forOthers || false;

    // check redirect
    this.redirect = false;
    if (!details || this.id !== details.id) {
      this.redirect = true;
    }
  }

  /* */
  componentDidMount() {
    const { details } = this.props;

    if (this.redirect) return;

    Booking.api.getTeetime(apolloClient, {
      date: details.date,
      time: details.time,
      golfId: details.golf.id,
      courseId: details.course.id,
      courseType: details.course.type,
    })
    // Promise.resolve(sampleData)
      .then((fromWs) => {
        const playersFromDb = details.players;

        this.playersFromPrima = fromWs.players;

        const checked = playersFromDb
          .filter(p1 => this.playersFromPrima.find(p2 => p1.tid === p2.tid));

        const others = this.playersFromPrima
          .filter(p1 => !checked.find(p2 => p1.tid === p2.tid));

        this.setState({
          details,
          accessories: fromWs.accessories,
          others: others.map(p => ({ ...p, reference: p.tid })),
          players: checked.map(p => ({ ...p, reference: p.tid })),
        });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  handleOpenDialog = type => () => {
    this.setState({ [`open${type}Dialog`]: true });
  };

  /* */
  handleCloseDialog = type => () => {
    this.setState({ [`open${type}Dialog`]: false });
  };

  /* */
  handleDeletePlayer = key => () => {
    this.setState(prevState => ({
      players: prevState.players.filter((p, k) => k !== key),
    }));
  }

  /* */
  handleSelect = (action, value) => {
    const { players, others } = this.state;

    const count = players.length;
    const remaining = TEE_TIME_MAX_PLAYERS - (count + others.length);

    if (action === 'select' && remaining > 0) {
      this.setState(prevState => ({
        players: [
          ...prevState.players,
          { ...value, tid: value.reference }, // add reference for comparing
        ],
      }));
      if (remaining === 1) this.setState({ openPartnersDialog: false });
    } else if (action === 'unselect' && count > 1) {
      this.setState(prevState => ({
        players: prevState.players.filter((p, k) => k !== value),
      }));
    }
  }

  /* */
  handleConfirm = async () => {
    const { players } = this.state;
    const { details } = this.props;

    const playersFromDb = details.players;

    const add = players
      .filter(p1 => !playersFromDb.find(p2 => p1.tid === p2.tid));
    const remove = playersFromDb
      .filter(p1 => !players.find(p2 => p1.tid === p2.tid));
    const noChanges = players
      .filter(p1 => !add.find(p2 => p1.tid === p2.tid)
        && !add.find(p3 => p1.tid === p3.tid));

    let onError = [];
    let promise = Promise.resolve([]);

    if (add.length > 0) {
      const params = {
        date: details.date,
        time: details.time,
        golfId: details.golf.id,
        courseId: details.course.id,
        courseType: details.course.type,
        players: add.map(p => p.reference),
      };
      promise = Booking.api.getTeetimePlayerPrices(apolloClient, params);
    }

    this.setState({ waitingConfirm: true });
    promise
      .then((res) => {
        onError = res.filter(p => p.price > 0);
        this.updates = { add, remove, noChanges };
        this.setState({ onError, openConfirmDialog: true });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ waitingConfirm: false }));
  }

  /* */
  handleUpdate = () => {
    const { updates } = this;
    const { history, details } = this.props;

    const { golf, course } = details;
    const { add, remove, noChanges } = updates;

    const players = [
      ...add.map(p => ({ ...p, todo: 'add' })),
      ...remove.map(p => ({ ...p, todo: 'cancel' })),
      ...noChanges.map(p => ({ ...p, todo: null })),
    ];

    const params = {
      id: details.id,
      date: details.date,
      time: details.time,
      golf: {
        id: golf.id,
      },
      course: {
        id: course.id,
        type: course.type,
        name: course.name,
      },
      players: players.map(p => ({
        tid: p.tid,
        todo: p.todo,
        email: p.email,
        lastname: p.lastname,
        firstname: p.firstname,
        bookingId: p.bookingId || null,
      })),
      accessories: details.accessories.map(acc => ({
        qty: acc.qty,
        name: acc.name,
      })),
    };

    this.setState({ waitingUpdate: true });
    Booking.api.bookingEdit(apolloClient, params)
    // Promise.resolve()
      .then(() => {
        this.setState({
          success: 'Votre réservation a été modifiée avec succès.',
        });
        history.push(`/booking/${this.id}`);
        this.props.detailBooking(null); // reset
      })
      .catch(e => this.setState({
        error: e.message,
        waitingUpdate: false,
        openConfirmDialog: false,
      }));
  }

  /* */
  renderHeader() {
    const { classes, details } = this.props;

    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(details.date)));
    const courseName = `${i18n.t(`terms.course_type_${details.course.type}`)} "${details.course.name}"`;

    return (
      <Box
        py={2}
        mb={2}
        className={classes.header}>
        <Typography
          align="center"
          color="textSecondary"
          component="h2"
          variant="h5">
          {details.golf.name}
        </Typography>
        <Typography
          align="center"
          component="p"
          variant="h5">
          {`${dateTxt} • ${Booking.functions.formatTime(details.time)}`}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6">
          {courseName}
        </Typography>
      </Box>
    );
  }

  /* */
  renderPlayers() {
    const { classes } = this.props;
    const { accountMatch, allowBookForOthers } = this;
    const { players, others, waitingConfirm } = this.state;

    const count = players.length;
    const remaining = TEE_TIME_MAX_PLAYERS - (count + others.length);

    return (
      <Grid
        item
        md={6} xs={12}
        className={classes.section}>
        {remaining > 0 && (
          <Box mb={2}>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={this.handleOpenDialog('Partners')}>
              {'Ajouter un joueur'}
            </Button>
          </Box>
        )}
        <Paper>
          <List disablePadding>
            {players.map((pl, key) => {
              const isOwner = pl.reference === accountMatch.reference;
              const disabled = (isOwner && !allowBookForOthers) || count === 1;

              return (
                <ListItem
                  divider
                  key={key}
                  disabled={disabled}>
                  <ListItemText primary={getPlayerName(pl)} />
                  {disabled && <LockIcon />}
                  {!disabled && count > 1 && (
                    <Button
                      size="small"
                      color="inherit"
                      onClick={this.handleDeletePlayer(key)}
                      className={classes.removeBtn}>
                      {'Supprimer'}
                    </Button>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Paper>
        <br />
        <Button
          fullWidth
          size="large"
          color="secondary"
          disabled={waitingConfirm}
          variant="contained"
          onClick={this.handleConfirm}>
          {waitingConfirm
            ? <CircularProgress color="secondary" size={24} />
            : 'Confirmer les changements'}
        </Button>
      </Grid>
    );
  }

  /* */
  renderOthers() {
    const { classes } = this.props;
    const { others, accessories } = this.state;

    if (others.length === 0 && accessories.length === 0) return null;

    const subtitle = others.length !== 0
      ? 'Autre(s) joueur(s) sur le départ'
      : 'Location(s) sur le départ';

    return (
      <Box mt={4}>
        <Divider />
        <br />
        <Typography
          paragraph
          component="h3"
          variant="h5">
          {subtitle}
        </Typography>
        <Grid container spacing={24}>
          {others.length > 0 && (
            <Grid
              item
              md={6} xs={12}
              className={classes.section}>
              <Paper>
                <List disablePadding>
                  {others.map((pl, key) => (
                    <ListItem divider key={key}>
                      <Avatar className={classes.avatar}>
                        <FaceIcon />
                      </Avatar>
                      <ListItemText primary={getPlayerName(pl)} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}
          {accessories.length > 0 && (
            <Grid
              item md={6} xs={12}
              className={classes.section}>
              <Paper>
                <List disablePadding>
                  {accessories.map((acc, key) => (
                    <ListItem divider key={key}>
                      <Avatar className={classes.avatar}>
                        {acc.qty}
                      </Avatar>
                      <ListItemText primary={acc.name} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  /* */
  renderPartnersDialog() {
    const { allowBookForOthers } = this;
    const { openPartnersDialog, players } = this.state;

    return (
      <ChoosePartners
        players={players}
        tabs={['members']}
        open={openPartnersDialog}
        onClick={this.handleSelect}
        disableOwner={!allowBookForOthers}
        onClose={this.handleCloseDialog('Partners')} />
    );
  }

  /* */
  renderConfirmDialog() {
    const {
      players,
      onError,
      waitingUpdate,
      openConfirmDialog,
    } = this.state;
    const { classes } = this.props;

    if (!this.updates) return null;

    const { add, remove } = this.updates;
    const countUpdates = add.length + remove.length;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={openConfirmDialog}
        onClose={this.handleCloseDialog('Confirm')}>
        <DialogTitle>{'Récapitulatif'}</DialogTitle>
        <DialogContent>
          {countUpdates === 0 && (
            <DialogContentText color="default">
              {'Aucun changement.'}
            </DialogContentText>
          )}
          {countUpdates > 0 && (
            <React.Fragment>
              <DialogContentText color="default">
                {'Confirmez-vous les changements ci-dessous ?'}
              </DialogContentText>
              {add.map((p, k) => (
                <Box
                  key={k}
                  display="flex"
                  alignItems="center"
                  flexDirection="row">
                  <AddIcon
                    fontSize="small"
                    color="secondary"
                    style={{ marginRight: 8 }} />
                  <DialogContentText key={k} variant="caption">
                    {getPlayerName(p)}
                  </DialogContentText>
                </Box>
              ))}
              {remove.map((p, k) => (
                <Box key={k}
                  display="flex"
                  alignItems="center"
                  flexDirection="row">
                  <RemoveIcon
                    color="error"
                    fontSize="small"
                    style={{ marginRight: 8 }} />
                  <DialogContentText color="error" variant="caption">
                    {getPlayerName(p)}
                  </DialogContentText>
                </Box>
              ))}
              <DialogContentText color="default" variant="caption">
                {'Un e-mail de confirmation sera envoyé aux autres joueurs.'}<br />
              </DialogContentText>
            </React.Fragment>
          )}
          {onError.length > 0 && (
            <Paper className={classes.alert}>
              {onError.map((err, k) => {
                const playerOnError = players.find(p => err.player === p.tid);
                if (!playerOnError) return null;

                return (
                  <Typography key={k} variant="caption" color="inherit">
                    <strong>{getPlayerName(playerOnError)}</strong>
                    {` : ${i18n.t(err.reason)}`}
                  </Typography>
                );
              })}
            </Paper>
          )}
        </DialogContent>
        <DialogActions>
          {!waitingUpdate && (
            <Button
              variant="text"
              color="secondary"
              onClick={this.handleCloseDialog('Confirm')}>
              {countUpdates > 0 ? 'Non' : 'Fermer'}
            </Button>
          )}
          {countUpdates > 0 && onError.length === 0 && (
            <Button
              color="secondary"
              disabled={waitingUpdate}
              variant="contained"
              onClick={this.handleUpdate}>
              {waitingUpdate
                ? <CircularProgress color="secondary" size={24} />
                : 'Oui'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  render() {
    if (this.redirect) { return <Redirect to="/booking" />; }

    const { error, loading, success } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title="Ma réservation"
        onBackPress={() => this.props.history.goBack()}
        helpURL={`${HELP_URL}/mes-r%C3%A9servations#h.p_zI6UzSITkgym`}>
        {this.renderHeader()}
        <Typography
          paragraph
          component="h3"
          variant="h5">
          {'Ma réservation'}
        </Typography>
        <Grid container spacing={24}>
          {this.renderPlayers()}
        </Grid>
        {this.renderOthers()}
        {this.renderConfirmDialog()}
        {this.renderPartnersDialog()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, bookingData: { details } }) => ({ app, details });

const StyledComponent = withStyles(styles)(BookingEdit);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
