import React from 'react';
import Icon from '@mdi/react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  mdiGolf,
  mdiGolfTee,
  mdiTennis,
  mdiBilliards,
  mdiRacquetball,
  mdiCardsPlayingOutline,
} from '@mdi/js';

/* */
const mdIcons = {
  golf: mdiGolf,
  tennis: mdiTennis,
  golfTee: mdiGolfTee,
  squash: mdiRacquetball,
  billiards: mdiBilliards,
  cardsPlaying: mdiCardsPlayingOutline,
};

/* */
const styles = theme => ({
  container: {
    padding: 16,
    marginRight: 16,
    minWidth: 100,
    cursor: 'pointer',
  },
  name: {
    marginTop: 8,
    color: theme.palette.secondary.dark,
  },
  mdIcon: {
    color: theme.palette.secondary.dark,
  },
  activeContainer: {
    backgroundColor: theme.palette.secondary.dark,
  },
  activeName: {
    marginTop: 8,
    color: theme.palette.common.white,
  },
  activeMdIcon: {
    color: theme.palette.common.white,
  },
});

/* */
const ActivityButton = withStyles(styles)(({
  name,
  active,
  mdIcon,
  onClick,
  classes,
}) => (
  <Paper
    onClick={onClick}
    className={classNames(
      classes.container, {
        [classes.activeContainer]: active,
      },
    )}
  >
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Icon
        path={mdIcons[mdIcon] || mdiGolf}
        size={1.2}
        className={classNames(
          classes.mdIcon, {
            [classes.activeMdIcon]: active,
          },
        )}
      />
      <Typography
        component="span"
        className={classNames({
          [classes.name]: !active,
          [classes.activeName]: active,
        })}
      >
        {name}
      </Typography>
    </Box>
  </Paper>
));

/* */
const ActivitySelector = ({
  data,
  value,
  onChange,
}) => {
  if (Object.keys(data).length === 0) return null;

  return (
    <Box
      mb={3}
      display="flex"
      flexDirection="row"
    >
      <ActivityButton
        name="Golf"
        active={value === 1}
        onClick={() => onChange(1)}
      />
      {Object.keys(data).map(id => (
        <ActivityButton
          key={id}
          name={data[id].name}
          mdIcon={data[id].mdIcon}
          active={value === Number(id)}
          onClick={() => onChange(Number(id))}
        />
      ))}
    </Box>
  );
};

export default ActivitySelector;
