import { App } from '@aps-management/primapp-common';
import firewall from '_config/firewall.json';
import defaultFeatures from '_config/features.json';

/* */
export const isFeatureEnabled = (golfFeatures = {}, feature) => {
  // deprecated
  if (typeof golfFeatures[feature] !== 'undefined') {
    return Boolean(golfFeatures[feature]);
  }

  // nouveau système
  const {
    enabled = [],
    disabled = [],
  } = golfFeatures;
  if (enabled.includes(feature)) return true;
  if (disabled.includes(feature)) return false;

  // par défaut
  return defaultFeatures[feature];
};

/* */
export const getAuthorization = (path, data) => {
  const { account, golf, user } = data;

  // test regex
  const key = Object.keys(firewall).find((f) => {
    const regex = new RegExp(`^${f}`);

    return regex.test(path);
  });

  const { type, ...params } = firewall[key];

  let isAuthorized;

  const match = App.functions.match(account, golf);

  const status = {
    isMatched: !!match,
    isMember: App.functions.isMember(account, golf),
    isAuthenticated: Boolean(user),
  };

  switch (type) {
    case 'anonymous':
      isAuthorized = !status.isAuthenticated;
      break;
    case 'private':
      isAuthorized = status.isAuthenticated;
      break;
    case 'matched':
      isAuthorized = status.isAuthenticated && status.isMatched;
      break;
    case 'member':
      isAuthorized = status.isAuthenticated && status.isMember;
      break;
    default:
      isAuthorized = true;
  }

  if (params.feature) {
    const golfFeatures = golf && golf.options && golf.options.features;
    isAuthorized = isAuthorized && isFeatureEnabled(golfFeatures, params.feature);
  }

  if (params.parent) {
    const parentAuth = getAuthorization(params.parent, data);
    isAuthorized = isAuthorized && parentAuth.isAuthorized;
  }

  return { isAuthorized, status, type };
};
