import i18n from 'i18n-js';
import { translations } from '@aps-management/primapp-common';

i18n.locale = 'fr'; // Localization.locale;
i18n.fallbacks = true;
i18n.defaultLocale = 'fr';
i18n.translations = translations;

i18n.getFromOpts = (scope, data, value) => {
  if (data && data.activities && data.activities[value]) {
    return data.activities[value].scopes[scope];
  }
  return i18n.t(scope);
};

i18n.t = (str, opts) => i18n.translate(str, { defaultValue: str, ...opts });

export default i18n;
