import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Typography } from '@material-ui/core';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
const Unauthorized = props => (
  <Screen noDrawer>
    <div className={props.classes.wrapper}>
      <LockOutlinedIcon
        className={props.classes.icon}
        fontSize="inherit" />
      <Typography
        gutterBottom
        variant="h4"
        component="h1">
        {'Accès refusé !'}
      </Typography>
      <Typography component="h2">
        {'Vous n\'êtes pas autorisé à consulter cette page.'}
      </Typography>
    </div>
  </Screen>
);

const StyledComponent = withStyles(styles)(Unauthorized);

export default StyledComponent;
