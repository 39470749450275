import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
const NotFound = props => (
  <Screen noDrawer>
    <div className={props.classes.wrapper}>
      <ErrorOutlineIcon
        className={props.classes.icon}
        fontSize="inherit" />
      <Typography
        component="h1"
        gutterBottom
        variant="h4">
        {'Oups !'}
      </Typography>
      <Typography
        component="p"
        gutterBottom
        variant="subtitle1">
        {'Erreur 404'}
      </Typography>
      <Typography
        component="h2"
        gutterBottom
        variant="h5">
        {'La page que vous cherchez semble introuvable.'}
      </Typography>
    </div>
  </Screen>
);

const StyledComponent = withStyles(styles)(NotFound);

export default StyledComponent;
