import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';

/* */
export default () => (
  <Box
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center">
    <CircularProgress
      size={40}
      thickness={4}
      color="inherit" />
  </Box>
);
