import React from 'react';
import _ from 'lodash/fp/lang';
import * as qs from 'query-string';
import { App, Account, Document } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import config from '_config';
import * as Screens from '_screens';
import I18n from '_utils/i18n';
import Router from '_config/router';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';
import Manifest from './utils/manifest';

/* */
class Bootstrap extends React.PureComponent {
  /* */
  componentDidMount() {
    const query = this.props.location && qs.parse(this.props.location.search);
    if (query && query.token) {
      this.props.session(query.token);
    } else if (config.token) { // dev only
      this.props.session(config.token);
    } else {
      this.props.noGolf();
      this.props.noAccount();
    }
  }

  /* */
  componentDidUpdate(prevProps) {
    const {
      user,
      golf,
      account,
    } = this.props;
    // console.log('componentDidUpdate', user);

    if (user && !_.isEqual(user, prevProps.user)) {
      // Load ACCOUNT
      if (user.accountId) {
        this.props.loadAccount(apolloClient, { id: user.accountId });
      } else {
        this.props.noAccount();
      }

      // Load APP GOLF
      if (user.golfId) {
        this.props.loadGolf(apolloClient, { id: user.golfId })
          .then((g) => {
            if (g) {
              I18n.locale = g.locale;
              global.origin = g.slug.toUpperCase();
            } else {
              this.props.noGolf();
            }
          });
      } else {
        this.props.noGolf();
      }
    }

    if (golf && account && !account.documentAcceptance) {
      const { license } = account.ffg;
      let promiseLicence = Promise.resolve(null);
      if (license) {
        promiseLicence = Account.api.getHcpIndexFFG(apolloClient, {});
      }
      Promise.allSettled([
        promiseLicence,
        Document.api.getDocumentAcceptance(masterClient,
          { accountId: account.id, golfId: golf.id }),
      ]).then(([ffg, documentAcceptance]) => {
        this.props.updateAccount({
          ...(ffg.value && { ffg: ffg.value }),
          documentAcceptance: documentAcceptance.value,
        });
      });
    }
  }

  // retry = () => {};

  /* */
  renderRoutes() {
    const {
      golf,
      user,
      error,
      account,
      loading,
    } = this.props;

    if (error) {
      return <Screens.Public.Error error={error} retry={this.retry} />;
    }

    const routerProps = { golf, user, account };

    // App loading
    if (loading) return <Screens.Loading />;

    if (golf && user) { // Logged in
      if (account) {
        // All required steps done
        return (
          <Router {...routerProps} />
        );
      }
      // Create account
      return <Screens.Public.RegisterFinal />;
    }

    // Invalid token
    return <Screens.Public.Unauthorized />;
  }

  /* */
  render() {
    const { golf } = this.props;

    // Golf loading
    if (golf === null) return <Screens.Loading />;

    if (!golf) return <Screens.Public.NotFound />;

    return (
      <BrowserRouter basename={'/'}>
        {this.slug && (<Manifest golf={golf} />)}
        {this.renderRoutes()}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(
  mapStateToProps,
  App.actions,
)(Bootstrap);
