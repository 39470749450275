import React from 'react';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  fluid: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      width: theme.breakpoints.values.sm - (theme.spacing.unit * 2 * 2),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md - (theme.spacing.unit * 2 * 2),
    },
  },
  fixed: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up(480 + (theme.spacing.unit * 2 * 2))]: {
      width: 480,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

const Wrapper = (props) => {
  const {
    classes,
    children,
    layout = 'fluid',
    component: Component = 'div',
  } = props;

  const className = layout === 'fluid' ? classes.fluid : classes.fixed;

  return (
    <Component className={className}>
      {children}
    </Component>
  );
};

export default withStyles(styles)(Wrapper);
