import React from 'react';
import moment from 'moment';
import * as qs from 'query-string';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Membership } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Card,
  List,
  Button,
  Divider,
  ListItem,
  CardHeader,
  Typography,
  CardActions,
  CardContent,
  ListItemText,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  summary: {
    marginBottom: `${theme.spacing.unit * 6}px`,
  },
  summaryCardActions: {
    display: 'inherit',
    padding: theme.spacing.unit * 1,
  },
  card: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  cardHeader: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  cardContent: {
    padding: 0,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  remaining: {
    color: theme.palette.error.main,
  },
  payButton: {
    margin: `0 0 ${theme.spacing.unit * 1}px`,
  },
  bold: { fontWeight: 'bold' },
  amount: { marginLeft: '.5rem', marginRight: '.5rem' },
});

/* */
class MembershipList extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      // Screen
      error: null,
      loading: false,
      // Data
      exercises: [],
    };

    const { redirect = null } = qs.parse(props.location.search);
    this.redirectTo = redirect;

    this.due = {
      total: 0,
      memberships: [],
    };
    this.options = [];

    this.globalPayment = {};

    this.subscriptionAllowed = false;
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { golf } = this.props;

    this.setState({
      error: null,
      exercises: [],
      loading: true,
    });

    Membership.api.getExercises(apolloClient, { golfId: golf.id })
      .then((res) => {
        if (!res.exercises) throw new Error('errors.user.not_found');

        const {
          exercises,
          globalPayment,
          subscriptionAllowed,
        } = res.exercises;

        const options = globalPayment.caseOptions
          ? globalPayment.caseOptions.map(o => o.option)
          : globalPayment.options;

        this.globalPayment = globalPayment;
        this.subscriptionAllowed = subscriptionAllowed;

        // init options
        this.options = (options || []).reduce((opts, option) => ({
          ...opts,
          [option]: 0,
        }), {});

        this.checkDue(exercises);
        this.setState({ exercises });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  checkDue(exercises) {
    const memberships = [];
    exercises.forEach((e) => {
      e.memberships.forEach((m) => {
        if (m.total.due > 0) {
          memberships.push(m);
        }
        if (m.total.options) {
          m.total.options.forEach(({ option, amount }) => {
            this.options[option] = (this.options[option] || 0) + amount;
          });
        }
      });
    });

    const total = memberships.reduce((counter, membership) =>
      counter + membership.total.due, 0);

    this.due = {
      total,
      memberships,
      redirect: this.redirectTo,
    };
  }

  /* */
  goToDetails(membership) {
    this.props.setMembership(membership);
    this.props.history.push('/membership/view');
  }

  /* */
  goToCashPayment() {
    const { due } = this;
    due.memberships.forEach((m, i) => {
      due.memberships[i] = {
        ...m,
        // compute membership total due based on option
        amount: m.total.due,
        transaction: {
          cash: true,
          schedule: null,
        },
      };
    });
    this.props.updateDue({
      ...due,
      cash: true,
      scheduleStartDate: null,
    });
    this.props.history.push('/membership/payment');
  }

  /* */
  goToApproveSchedule(months) {
    const { due, globalPayment } = this;

    due.memberships.forEach((m, i) => {
      // compute membership total due based on option
      const option = (m.total.options || []).find(o => o.option === Number(months));
      due.memberships[i] = {
        ...m,
        amount: option ? option.amount : m.total.due,
        transaction: {
          cash: false,
          schedule: [],
        },
      };
    });

    const caseOption = (globalPayment.caseOptions || []).find(o => (o.option === Number(months)));
    this.props.updateDue({
      ...due,
      cash: false,
      options: this.options,
      total: this.options[months] || due.total,
      scheduleStartDate: caseOption
        ? caseOption.scheduleStartDate
        : globalPayment.scheduleStartDate,
    });
    this.props.history.push(`/membership/schedule/${months}`);
  }

  /* */
  renderMembership(membership, index) {
    const { classes, wording } = this.props;

    const type = Membership.functions.isPrincipal(membership) ? 'principal' : 'additional';
    const scopeTitle = `membership.titles.${type}_membership_${wording.membership}`;
    const createdAt = moment(membership.createdAt, 'YYYYMMDD').toDate();
    const totalAmount = membership.total.amount / 100;
    const remainingAmount = (membership.total.amount - membership.total.paid) / 100;

    return (
      <Card className={classes.card} key={index}>
        <CardHeader
          title={i18n.t(scopeTitle)}
          className={classes.cardHeader}
          titleTypographyProps={{ color: 'inherit', variant: 'body1' }} />
        <CardContent className={classes.cardContent}>
          <List disablePadding>
            <ListItem divider>
              <ListItemText primary={i18n.l('date.formats.default', createdAt)} />
              <Typography variant="subtitle1">
                {i18n.l('currency', totalAmount)}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText primary={'Montant restant dû'} />
              <Typography
                className={classNames({ [classes.remaining]: remainingAmount > 0 })}
                variant="subtitle1">
                {i18n.l('currency', remainingAmount)}
              </Typography>
            </ListItem>
          </List>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => this.goToDetails(membership)}>
            {'Plus de détails'}
          </Button>
        </CardActions>
      </Card>
    );
  }

  /* */
  renderExercisesList() {
    const { exercises } = this.state;
    const { subscriptionAllowed } = this;

    const nothing = exercises.length === 0
      || (exercises.length === 1 && exercises[0].memberships.length === 0);

    return !nothing
      ? (exercises.map(e => this.renderExercise(e)))
      : (!subscriptionAllowed && (
          <Typography align="center" variant='h5'>
            {'Aucune cotisation.'}
          </Typography>)
      );
  }

  /* */
  renderExercise(exercise) {
    return (
      <React.Fragment key={exercise.year}>
        <Typography variant="h5" gutterBottom>
          {exercise.year}
        </Typography>
        <Divider />
        {exercise.memberships.map((membership, i) => this.renderMembership(membership, i))}
      </React.Fragment>
    );
  }

  /* */
  renderPaymentOption(months, i) {
    const { due, globalPayment } = this;
    const { classes } = this.props;

    const caseOption = (globalPayment.caseOptions || []).find(o => (o.option === Number(months)));
    const scheduleDeadlineDate = moment.max(moment(), caseOption
      ? moment(caseOption.scheduleDeadlineDate)
      : moment(globalPayment.scheduleDeadlineDate));
    const scheduleStartDate = moment.max(moment().add(1, 'days'), caseOption
      ? moment(caseOption.scheduleStartDate)
      : moment(globalPayment.scheduleStartDate));

    const text = (this.options[months] && this.options[months] !== due.total)
      ? <span className={classes.amount}>{i18n.l('currency', (this.options[months]) / 100)}</span>
      : '';

    return (
      <React.Fragment key={i}>
        <Button
          fullWidth
          size="medium"
          color="secondary"
          variant="outlined"
          className={classes.payButton}
          onClick={() => this.goToApproveSchedule(months)}>
          {'Payer'}{text}{` en ${months} mensualités`}
        </Button>
        {(scheduleDeadlineDate || scheduleStartDate) && (
          <Box mb={2}>
            {scheduleDeadlineDate.isValid() && (
              <Typography color="textSecondary" align="center">
                {`Vous avez la possibilité de passer en paiement mensuel jusqu'au ${i18n.l('date.formats.default', scheduleDeadlineDate.toDate())}. `}
              </Typography>
            )}
            {scheduleStartDate.isValid() && (
              <Typography color="textSecondary" align="center">
                {`La première échéance sera au ${i18n.l('date.formats.default', scheduleStartDate.toDate())}.`}
              </Typography>
            )}
          </Box>
        )}
      </React.Fragment>
    );
  }

  /* */
  renderDue() {
    const { due } = this;
    const { classes, wording } = this.props;

    if (due.total === 0) return null;

    const restOptions = Object.keys(this.options || {}).filter(opt => opt > 1);

    return (
      <div className={classes.summary}>
        <Typography variant="h5" gutterBottom>
          {'A régler'}
        </Typography>
        <Divider />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List disablePadding>
              {due.memberships.map((membership, i) => {
                const type = Membership.functions.isPrincipal(membership) ? 'principal' : 'additional';
                const scopeTitle = `membership.titles.${type}_membership_${wording.membership}`;

                return (
                  <ListItem divider key={i}>
                    <ListItemText primary={`${membership.year} - ${i18n.t(scopeTitle)}`} />
                    <Typography variant="subtitle1">
                      {i18n.l('currency', membership.total.due / 100)}
                    </Typography>
                  </ListItem>
                );
              })}
              <ListItem divider>
                <ListItemText
                  primary="Total"
                  classes={{ primary: classes.bold }} />
                <Typography
                  variant="subtitle1"
                  className={classes.bold}>
                  {i18n.l('currency', due.total / 100)}
                </Typography>
              </ListItem>
            </List>
          </CardContent>
          <CardActions className={classes.summaryCardActions}>
            {Boolean(this.options[1]) && (
              <Button
                fullWidth
                size="medium"
                color="secondary"
                variant="contained"
                className={classes.payButton}
                onClick={() => this.goToCashPayment()}>
                {'Payer comptant'}
              </Button>
            )}
            {(restOptions.length > 0) && restOptions.map(this.renderPaymentOption.bind(this))}
          </CardActions>
        </Card>
      </div>
    );
  }

  /* */
  renderSubscribeButton() {
    const { subscriptionAllowed } = this;

    if (!subscriptionAllowed) return null;

    return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Typography variant='h5' style={{ marginRight: 20 }}>
            {'Vous souhaitez devenir membre ?'}
          </Typography>
          <Button
            size="medium"
            color="secondary"
            variant="contained"
            onClick={() => this.props.history.push('/membership/subscribe')}>
            {"S'abonner"}
          </Button>
      </Box>
    );
  }

  /* */
  render() {
    const { wording, location, history } = this.props;
    const { from } = qs.parse(location.search);
    const { error, loading } = this.state;
    const title = i18n.t(`home.titles.my_membership_${wording.membership}`);
    return (
      <Screen
        error={error}
        title={title}
        loading={loading}
        onRetry={this.load}
        onBackPress={from ? () => history.goBack() : null}>
        {this.renderSubscribeButton()}
        {this.renderDue()}
        {this.renderExercisesList()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, membershipData }) => ({
  golf: app.golf,
  data: membershipData,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(MembershipList);

export default connect(
  mapStateToProps,
  Membership.actions,
)(StyledComponent);
